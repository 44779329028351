import React from "react";
import horizontal from "../../images/korplat.mp4";

export default function VideoBackground() {
  return (
    <section className="video-wrapper h-full w-full overflow-hidden cover object-cover">
      <video
        autoPlay
        playsInline
        muted
        loop
        id="video"
        className="object-cover max-h-auto inset-0 w-full h-full"
      >
        <source src={horizontal} type="video/mp4" />
      </video>
    </section>
  );
}

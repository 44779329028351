import { Link } from "gatsby";
import React, { useState } from "react";
import Logo from "./Logo";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className=" text-brand">
      <div className="flex flex-wrap items-center justify-between px-4 md:px-16 py-4">
        <div className="w-64">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <button
          className="items-center block px- py-2 text-brand md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-8 h-6 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `Hem`,
            },
            {
              route: `/#om`,
              title: `Om oss`,
            },
            {
              route: `/kontakt`,
              title: `Kontakt`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-base font-medium no-underline md:inline-block md:mt-0 md:ml-12"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;

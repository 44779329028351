import React from "react";
import VideoBackground from "./Videobackground";
// import Vimeo from "@u-wave/react-vimeo";

export default function Hero() {
  return (
    <div className="h-full">
      <div className="h-full relative flex flex-col justify-end items-end">
        <div className="vimeo-wrapper bg-black">
          <VideoBackground />
        </div>
        <div className="relative pb-4 md:pb-8 px-4 md:px-8">
          <div className="bg-secondary bg-opacity-90 max-w-lg p-6 rounded space-y-2">
            <h1 className=" font-sans text-lg md:text-3xl font-bold tracking-tight leading-normal text-brand text-center">
              Välkommen till Körplåt i Skåne AB
            </h1>
            <div>
              <p className="text-base pb-4 text-center">
                Hos oss kan du hyra körplåtar för alla behov! Vi har tusentals
                plåtar i olika storlekar och tjocklekar.
              </p>
            </div>

            <div className="flex items-center  justify-center text-sm md:text-xl">
              <a
                href="/kontakt"
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wider text-secondary transition duration-200 rounded shadow-sm bg-brand hover:bg-opacity-80 focus:shadow-outline focus:outline-none"
              >
                Kontakta oss
              </a>
              <a
                href="/#om"
                aria-label=""
                className="inline-flex items-center rounded font-medium h-12 px-6 bg-white text-brand transition-colors duration-200 hover:opacity-70"
              >
                Läs mer
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function Footer() {
  return (
    <footer className="bg-brand text-gray-100">
      <nav className="flex justify-center max-w-4xl p-4 mx-auto text-sm md:p-8">
        <p>
          Körplåt i Skåne AB, Jörgen Kocksgatan 75, 211 20 Malmö,
          +46 (0)40-39 49 00
        </p>
      </nav>
    </footer>
  );
}
